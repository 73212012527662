<i18n src="./translations"></i18n>

<template>
  <div class="price-tag" :class="{ kvi: isKvi }">
    <price-discount-label
      :is-kvi="isKvi"
      :type="discountLabel.type"
      :text="discountLabel.text"
    />

    <p class="display-price" :class="{ 'is-discounted': showRRP }">
      <strong>{{ displayPrice }}</strong>
      <span v-if="isBusinessCustomer" class="net">
        {{ $t('price-tag.net') }}
      </span>
    </p>

    <p v-if="showRRP" class="retail-price">
      {{ $t('price-tag.rrp') }}: {{ priceTexts.recommendedRetailPrice }}

      <price-discount-badge
        :value="displayDiscountPercentage"
        :is-kvi="isKvi"
      />
    </p>

    <p class="price-details">
      <span v-if="showUnitInfo" class="unit-info">
        {{ displayPricePerUnit }}
      </span>

      <span v-if="showTaxInfo" class="tax-info">
        {{ $t('price-tag.tax-info', { '%tax%': priceTexts.tax })
        }}{{ showShippingInfo || showDepositInfo ? ', ' : '' }}
      </span>

      <template v-if="showShippingInfo">
        <MaskedLink
          class="shipping-info"
          :text="$t('price-tag.shipping-info')"
          :redirect-to="frontFacingPath('content.page.shipping')"
          with-underline
          target="_blank"
        />{{ showDepositInfo ? ', ' : '' }}
      </template>

      <span v-if="showDepositInfo" class="deposit-info">
        {{
          $t('price-tag.deposit-info', { '%price%': priceTexts.depositPrice })
        }}
      </span>
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import globalMixin from 'Libs/mixins/globalMixin';
import PriceDiscountLabel from 'Components/01-atoms/price-discount-label/PriceDiscountLabel.vue';
import PriceDiscountBadge from 'Components/01-atoms/price-discount-badge/PriceDiscountBadge.vue';
import MaskedLink from 'Components/01-atoms/masked-link/MaskedLink';

export default {
  name: 'PriceTag',
  components: {
    MaskedLink,
    PriceDiscountLabel,
    PriceDiscountBadge,
  },
  mixins: [globalMixin],
  props: {
    unitPrices: {
      type: Array,
      default: () => [
        {
          price: '00,00 €',
        },
      ],
    },
    priceTexts: {
      type: Object,
      default: () => ({
        tax: 19,
        recommendedRetailPrice: '00,00 €',
      }),
    },
    prices: {
      type: Object,
      default: () => ({
        price: 0,
      }),
    },
    isKvi: {
      type: Boolean,
      default: false,
    },
    discountLabel: {
      type: Object,
      default: () => ({
        type: '',
        text: '',
      }),
    },
  },
  computed: {
    ...mapGetters('core', ['isBusinessCustomer']),
    displayPrice() {
      // B2B => net price is displayed
      if (this.isBusinessCustomer) {
        const B2BDiscountedSalesPrice =
          this.calculatedDiscountedSalesValueNet || 0;
        const B2BPrice = this.unitPrices?.[1]?.price || 0;

        return B2BDiscountedSalesPrice || B2BPrice;
      }
      // B2C => Gross price is displayed
      const B2CDiscountedSalesPrice =
        this.priceTexts?.discountedSalesValueGross || 0;
      const B2CPrice = this.unitPrices?.[0]?.price || 0;

      return B2CDiscountedSalesPrice || B2CPrice;
    },
    displayPricePerUnit() {
      const pricePerUnit = this.priceTexts.upToDiscountPercentage
        ? this.priceTexts?.discountedPricePerUnit.value
        : this.priceTexts.pricePerUnit.value;

      return `${pricePerUnit}/${this.priceTexts.pricePerUnit.unit}`;
    },
    displayDiscountPercentage() {
      // const upToDiscountPercentage =
      //   this.priceTexts.upToDiscountPercentage || 0;

      // return this.priceTexts.discountPercentage + upToDiscountPercentage;
      return this.priceTexts.discountPercentage;
    },
    calculatedDiscountedSalesValueNet() {
      // *** For B2B only *** //
      /* TBD-[Mina]: should be replaced with discountedSalesValueNet when the BE addes it
       priceNet - (priceNet * upToDiscountPercentage ) */
      const priceNetLocalized = this.unitPrices?.[1]?.price;
      const priceNet = this.prices?.priceNet;

      if (!priceNetLocalized || !priceNet) return null;

      const isComma = priceNetLocalized.includes(',');
      const currency = priceNetLocalized
        .split(/(\s+)/)
        .filter((e) => e.trim().length > 0)[1];
      const discountPercentage = this.priceTexts.upToDiscountPercentage / 100;
      // Calc discount
      let discountedSalesValueNet = (
        priceNet -
        priceNet * discountPercentage
      ).toFixed(2);
      // Localize it same as priceNetLocalized
      discountedSalesValueNet = isComma
        ? discountedSalesValueNet.toString().replace('.', ',')
        : discountedSalesValueNet;
      // Add currency same as priceNetLocalized
      discountedSalesValueNet = currency
        ? `${discountedSalesValueNet} ${currency}`
        : discountedSalesValueNet;

      return discountedSalesValueNet;
    },
    showRRP() {
      // Hide RRP if not available
      // Also hide when there is a percentage restriction (<3% and >95%)
      return !(
        !this.priceTexts.recommendedRetailPrice ||
        this.displayDiscountPercentage < 3 ||
        this.displayDiscountPercentage > 95
      );
    },
    showUnitInfo() {
      return this.priceTexts.pricePerUnit && this.priceTexts.pricePerUnit.value;
    },
    showTaxInfo() {
      return !this.isBusinessCustomer && this.priceTexts.tax;
    },
    showShippingInfo() {
      const isDirectDelivery =
        (this.isBusinessCustomer &&
          this.getCompanyInformation?.isDirectDelivery) ||
        false;
      return !isDirectDelivery;
    },
    showDepositInfo() {
      return this.priceTexts.depositPrice;
    },
  },
};
</script>

<style scoped lang="scss">
.price-tag {
  .display-price {
    strong {
      color: var(--color-rebranding-black-out);
      font-size: var(--font-size-XL);
      font-weight: 700;
    }

    .net {
      font-size: var(--font-size-S);
    }

    &.is-discounted strong {
      color: var(--color-rebranding-semantic-red-discount);
    }
  }

  .retail-price {
    display: flex;
    align-items: center;
    color: var(--color-rebranding-black-out);
    margin-top: var(--space-0--half);
    font-size: var(--font-size-S);
  }

  .price-details {
    color: var(--color-rebranding-manatee);
    margin-top: var(--space-1);
    font-size: var(--font-size-S);

    .unit-info {
      display: block;
      margin-bottom: var(--space-0--half);
    }
  }

  &.kvi {
    .display-price strong {
      color: var(--color-primary);
    }
  }
}
</style>
