<template>
  <svg
    class="price-discount-badge"
    :class="{ kvi: isKvi }"
    width="40"
    height="18"
    viewBox="0 0 40 18"
  >
    <path d="M6 0L1 9L6 18" fill="none" />
    <line x1="6" y1="0" x2="40" y2="0" stroke-width="2" />
    <line x1="40" y1="0" x2="40" y2="18" stroke-width="2" />
    <line x1="6" y1="18" x2="40" y2="18" stroke-width="2" />
    <text x="22" y="13" font-weight="bold" text-anchor="middle" stroke="none">
      -{{ value }}%
    </text>
  </svg>
</template>

<script>
export default {
  name: 'PriceDiscountBadge',
  props: {
    value: {
      type: String,
      default: '',
    },
    isKvi: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.price-discount-badge {
  margin-left: var(--space-0--half);
  fill: var(--color-rebranding-semantic-red-discount);
  stroke: var(--color-rebranding-semantic-red-discount);

  &.kvi {
    fill: var(--color-primary);
    stroke: var(--color-primary);
  }
}
</style>
