<i18n src="./translations"></i18n>

<template>
  <p
    v-if="showLabel"
    class="price-discount-label"
    :class="{
      kvi: isKvi,
      regular: discountType === DiscountTypeEnum.REGULAR,
      blackfriday: discountType === DiscountTypeEnum.BLACKFRIDAY,
    }"
  >
    <span v-if="isKvi">
      {{ $t('price-discount-label.kvi') }}
    </span>
    <span
      v-else-if="
        [DiscountTypeEnum.REGULAR, DiscountTypeEnum.BLACKFRIDAY].includes(
          discountType
        )
      "
    >
      {{ text }}
    </span>
  </p>
</template>

<script>
import { mapState } from 'vuex';
import { DiscountTypeEnum } from './models/discount-type.enum';

export default {
  name: 'PriceDiscountLabel',
  props: {
    isKvi: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      DiscountTypeEnum,
    };
  },
  computed: {
    ...mapState('core', {
      upToDiscountEnabled: ({ salesChannel }) =>
        !!salesChannel?.config?.upToDiscountEnabled,
    }),
    discountType() {
      return this.type?.toLowerCase();
    },
    showLabel() {
      if (this.isKvi) return true;

      if (!this.upToDiscountEnabled) return false;

      return [DiscountTypeEnum.REGULAR, DiscountTypeEnum.BLACKFRIDAY].includes(
        this.discountType
      );
    },
  },
};
</script>

<style scoped lang="scss">
.price-discount-label {
  position: relative;
  padding: 0 var(--space-1);
  margin-bottom: var(--space-0--half);
  font-size: var(--font-size-S);
  font-weight: 700;
  color: var(--color-white);
  height: var(--space-2--half);
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &::after {
    content: '';
    display: inline-block;
    width: var(--space-3);
    height: var(--space-3);
    background-color: var(--color-white);
    transform: rotate(45deg);
    margin-left: var(--space-1--half);
    margin-right: calc(var(--space-2--half) * -1);
  }

  &.kvi {
    background-color: var(--color-primary);
  }

  &.regular {
    background-color: var(--color-rebranding-semantic-red-discount);
  }

  &.blackfriday {
    background-color: var(--color-black);
  }
}
</style>
