var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showLabel)?_c('p',{staticClass:"price-discount-label",class:{
    kvi: _vm.isKvi,
    regular: _vm.discountType === _vm.DiscountTypeEnum.REGULAR,
    blackfriday: _vm.discountType === _vm.DiscountTypeEnum.BLACKFRIDAY,
  }},[(_vm.isKvi)?_c('span',[_vm._v("\n    "+_vm._s(_vm.$t('price-discount-label.kvi'))+"\n  ")]):(
      [_vm.DiscountTypeEnum.REGULAR, _vm.DiscountTypeEnum.BLACKFRIDAY].includes(
        _vm.discountType
      )
    )?_c('span',[_vm._v("\n    "+_vm._s(_vm.text)+"\n  ")]):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }