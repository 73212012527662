var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.classes,attrs:{"data-preload":_vm.$options.name}},[_c('div',{staticClass:"price-info-21__prices qa-priceInfo21__prices"},[(_vm.uvpLegalSafeActive)?_c('PriceTag',{attrs:{"unit-prices":_vm.product.unitPrices,"price-texts":_vm.product.priceTexts,"prices":_vm.product.prices,"is-kvi":_vm.isKvi,"discount-label":_vm.priceDiscountLabel}}):_vm._e(),_vm._v(" "),(!_vm.uvpLegalSafeActive)?[(_vm.product.unitPrices && _vm.product.unitPrices[0])?_c('div',{staticClass:"price-info-21__price-wrap"},[(!_vm.showUpToDiscountedPrice)?_c('Price',{staticClass:"price-info-21__main-price qa-PriceInfo21__mainPrice",attrs:{"label":_vm.product.unitPrices[0].label,"is-kvi":_vm.isKvi,"value":_vm.getPrice,"is-net":_vm.isBusinessCustomer,"modifiers":_vm.priceModifiers}}):_c('PriceDiscounted',{attrs:{"is21":"","price-texts":_vm.product.priceTexts,"unit-prices":_vm.product.unitPrices,"prices":_vm.product.prices,"price-modifiers":_vm.priceModifiers,"is-kvi":_vm.isKvi}})],1):_vm._e(),_vm._v(" "),(_vm.showRecommendedPrice)?_c('span',{staticClass:"price-info-21__retail-price-container qa-PriceInfo__retailPrice"},[_vm._v("\n        "+_vm._s(_vm.$t('price-info-21.price_text_uvp'))+"\n        "),_c('span',{staticClass:"price-info-21__retail-price",class:{
            'is-striked': !_vm.isBusinessCustomer,
          }},[_vm._v(_vm._s(_vm.product.priceTexts.recommendedRetailPrice))])]):_vm._e(),_vm._v(" "),_c('price-savings',{attrs:{"product":_vm.product,"is21":""}}),_vm._v(" "),_c('div',{staticClass:"price-info-21__price-tax"},[(_vm.product.priceTexts && _vm.pricePerUnit && _vm.pricePerUnit.value)?_c('span',{staticClass:"price-info-21__unit-price qa-PriceInfo21__unitPrice"},[_vm._v("\n          "+_vm._s(_vm.pricePerUnit.value)+"/"+_vm._s(_vm.pricePerUnit.unit)+",\n        ")]):_vm._e(),_vm._v(" "),(_vm.showTaxes)?_c('span',{staticClass:"qa-PriceInfo21__tax"},[_vm._v("\n          "+_vm._s(_vm.$t('price-info-21.tax_text', {
              '%taxPercentage%': _vm.product.priceTexts.tax,
            }))+",\n        ")]):_vm._e(),_vm._v(" "),(!_vm.isDirectDelivery)?_c('MaskedLink',{staticClass:"price-info-21__shipping",attrs:{"text":`${_vm.$t('price-info-21.excluding-shipping')}${
            _vm.hasDepositPrice ? ',' : ''
          }`,"redirect-to":_vm.frontFacingPath('content.page.shipping'),"with-underline":true,"target":"_blank"}}):_vm._e(),_vm._v(" "),(_vm.hasDepositPrice)?_c('span',{staticClass:"qa-PriceInfo21__deposit"},[_vm._v(_vm._s(_vm.$t('price-info-21.deposit_price', {
            '%formattedDepositPrice%': _vm.product.priceTexts.depositPrice,
          })))]):_vm._e()],1)]:_vm._e(),_vm._v(" "),_c('div',{staticClass:"price-info-21__availability-wrapper"},[_c('PdpAvailability',{staticClass:"price-info-21__availability",class:[
          `qa-availability--${_vm.productAvailability} ${
            _vm.isDesktop ? 'qa-Availability__Desktop' : ''
          }`,
        ],attrs:{"state":_vm.productAvailability}}),_vm._v(" "),_c('BranchesAvailabilityLink',{attrs:{"product":_vm.product,"label-type":"secondary"}})],1),_vm._v(" "),(_vm.priceTextsEnabled)?_c('PriceTexts',{attrs:{"modifiers":_vm.modifiers.includes('slim') ? ['slim'] : [],"price-texts":_vm.product.priceTexts}}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }